import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 2-2-2-2-2\\@90% 1RM`}</p>
    <p>{`Glute Ham Raise 4-4-4-4-4 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p>{`12:00 AMRAP of:`}</p>
    <p>{`5/leg-Pistols`}</p>
    <p>{`1 Rope Climb, 15′`}</p>
    <p>{`5 Back Squats (225/155)`}</p>
    <p>{`2 Rope Climbs, 15′`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our 2nd Annual Pre Open in house competition will start January 1st
so great ready! RX Men, RX Women, scaled, and masters (50+) divisions.
More details to come.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      